<template>
  <div class="gps-track">
    <div v-bind:class="mobileMenuActive ? 'block' : 'hidden'" @click="closeMenu()" class="fixed z-20 inset-0 bg-black opacity-50 transition-opacity lg:hidden"></div>
    <div class="flex flex-wrap unit-gps-wrapper">
      <div class="w-full lg:w-5/6 map-container">
        <GmapMap :center="center" :zoom="zoom" map-type-id="terrain" class="google_map_wrapper_alarm_center" ref="map" :options="{ fullscreenControl: mapFullScreenControl }">
          <GmapInfoWindow
            :options="infoOptions"
            :position="infoWindowPos"
            :opened="infoWinOpen"
            @closeclick="
              infoWinOpen = false;
              alarm_id = '';
            "
          ></GmapInfoWindow>
          <GmapCluster :zoom-on-click="true">
            <GmapMarker v-for="(m, index) in markers_filtered" :position="m.position" :clickable="true" :draggable="false" @mouseover="toggleInfoWindow(m, index)" @mouseout="toggleInfoWindow(m, index)" :key="index" :icon="{ url: m.icon }"></GmapMarker>
          </GmapCluster>
          <GmapCircle v-for="(pin, index) in alarmCircles_filtered" :key="index" :center="pin.position" :visible="true" :options="pin.options"></GmapCircle>
          <GmapPolyline v-for="p in push_button_paths_filtered" :key="p.imei_number" :path="p.path" :options="{ strokeColor: 'red' }" @mouseover="showInfoWindow($event, p.infoText, true)" @mouseout="showInfoWindow($event, p.infoText, false)"></GmapPolyline>
          <GmapPolyline v-for="(p, i) in track_alarm_paths_filtered" :key="p.imei_number + `-${i}`" :path="p.path" :options="{ strokeColor: '#1a8de9' }" @mouseover="showInfoWindow($event, p.infoText, true)" @mouseout="showInfoWindow($event, p.infoText, false)"></GmapPolyline>
        </GmapMap>
      </div>
      <transition enter-active-class="transition ease-out duration-100" enter-class="transform translate-x-2 opacity-0" enter-to-class="transform translate-x-0 opacity-100" leave-active-class="transition ease-in duration-75" leave-class="transform translate-x-0 opacity-100" leave-to-class="transform translate-x-2 opacity-0">
        <div class="absolute top-0 right-0 min-h-screen w-4/6 md:w-2/6 lg:min-h-full lg:w-1/6 bg-white position-inherit z-30 border border-secondary-300" v-if="mobileMenuActive">
          <AlarmSound ref="audioControl" :audioSrc="require('../../assets/audios/emergency-alarm.mp3')" :alarmSoundLabel="$t('alarm_sound')" :initialMuteState="false" :alarmDuration="180" />
          <AlarmSearch @queryUpdated="handleSearch" />
          <div class="alarm-list-wrapper" v-bind:class="{ search: search_active }" v-if="gps_alarms_filtered">
            <div class="text-center border-b border-secondary-400" v-if="gps_alarms_filtered && gps_alarms_filtered.length == 0">
              <div class="py-3 w-full">{{ $t("no_alarm_found") }}</div>
            </div>
            <div v-for="(a, index) in filteredGPSAlarms" :key="index" class="flex flex-col hover:bg-primary-50">
              <div @click="focusAlarm(a.imei_number)" class="cursor-pointer py-2 px-3 border-b border-secondary-300" v-bind:class="{ 'bg-red-500 hover:bg-red-500 triggered': a.pushButton, 'bg-primary-50': alarm_id == a.alarm_id && !a.pushButton }">
                <div class="item text-sm font-bold text-primary-500">{{ a.customer_name }}</div>
                <div class="item text-sm font-bold">{{ a.unit_name }}</div>
                <div class="item text-sm mt-2">{{ a.alarm_name }}</div>
                <div class="item text-sm">{{ a.phone }}</div>
                <div class="item text-sm">{{ a.imei_number }}</div>
                <div class="item text-sm mt-3 flex flex-wrap" v-bind:class="{ 'opacity-30': a.lastSeenTime > 1000 * 60 * 60 * 4 }">
                  <span class="mr-2">
                    <BaseIcon v-if="a.batteryBars == 4" icon="battery-full" class="text-accent-500" />
                    <BaseIcon v-else-if="a.batteryBars == 3" icon="battery-three-quarters" class="text-accent-500" />
                    <BaseIcon v-else-if="a.batteryBars == 2" icon="battery-half" class="text-accent-500" />
                    <BaseIcon v-else-if="a.batteryBars == 1" icon="battery-quarter" v-bind:class="a.pushButton ? 'text-white' : 'text-red-500'" />
                    <BaseIcon v-else-if="a.batteryBars == 0" icon="battery-empty" v-bind:class="a.pushButton ? 'text-white' : 'text-red-500'" />
                  </span>
                  <span v-if="a.battery_charging" class="mr-2">
                    <BaseIcon icon="charging-station" v-bind:class="a.pushButton ? 'text-white' : 'text-red-500'" />
                  </span>
                  <span v-if="a.signalBars" class="mr-2">
                    <img v-if="a.signalBars == 4" :src="require('../../assets/icon/signal/signal-4.svg')" class="w-4" />
                    <img v-else-if="a.signalBars == 3" :src="require('../../assets/icon/signal/signal-3.svg')" class="w-4" />
                    <img v-else-if="a.signalBars == 2" :src="require('../../assets/icon/signal/signal-2.svg')" class="w-4" />
                    <img v-else-if="a.signalBars == 1" :src="require('../../assets/icon/signal/signal-1.svg')" class="w-4" />
                  </span>
                  <span v-if="a.accuracyRadius > 0" class="mr-2">
                    <BaseIcon icon="map-marker-alt" class="mr-1" />
                    <span class="text-xs">{{ a.accuracyRadius }}m</span>
                  </span>
                  <span v-if="a.tagFound == true" class="mr-2">
                    <img :src="require('../../assets/icon/rf_signal.svg')" class="w-4" />
                  </span>
                  <span v-if="a.type == 'SRT326'" class="mr-2">
                    <img :src="require('../../assets/icon/location_off.svg')" class="w-4" />
                  </span>
                  <span v-if="new RegExp(/^TELTONIKA/g).test(a.type)" class="mr-2">
                    <BaseIcon icon="gas-pump" class="mr-1" />
                    <span class="text-xs">{{ a.obdOemFuelLevel }} Ltr</span>
                  </span>
                  <span v-if="new RegExp(/^TELTONIKA/g).test(a.type)" class="mr-2">
                    <BaseIcon icon="tachometer-alt" class="mr-1" />
                    <span class=" text-xs">{{ a.totalOdometer }} Km</span>
                  </span>
                </div>
                <div class="item text-xxs mt-1 text-secondary-600 group-hover:text-white">
                  <span v-if="new RegExp('^SRT').test(a.type)" class="last-seen mr-1" v-bind:class="{ inactive: a.lastSeenTime > 1000 * 60 * 60 }">⬤</span>
                  <span v-if="new RegExp('^TWIG').test(a.type)" class="last-seen mr-1" v-bind:class="{ inactive: a.lastSeenTime > 1000 * 60 * 60 * 2.5 }">⬤</span>
                  <span v-if="new RegExp('^EMERIT').test(a.type)" class="last-seen mr-1" v-bind:class="{ inactive: a.lastSeenTime > 1000 * 60 * 60 }">⬤</span>
                  <span v-if="new RegExp('^TELTONIKA').test(a.type)" class="last-seen mr-1" v-bind:class="{ inactive: a.lastSeenTime > 1000 * 60 * 60 }">⬤</span>
                  {{ a.lastSeen }}
                </div>
                <div class="mt-2" v-if="a.pushButton || a.geofenceExit || (a.speed > 5 && a.lastSeenTime < 1000 * 60 * 30) || a.tracking_active">
                  <button v-bind:class="a.tracking_active ? 'btn-red' : 'btn-green'" v-on:click.stop="trackAlarm(a, index)">
                    <span v-if="a.tracking_active">{{ $t("secur_track.disable_tracking") }}</span>
                    <span v-else>{{ $t("secur_track.enable_tracking") }}</span>
                  </button>
                </div>
                <div class="mt-2" v-if="a.show_test_alarm">
                  <button class="btn-blue" v-on:click.stop="confirmTestAlarmPrompt(a, index)">{{ $t("secur_track.test_alarm_confirmed") }}</button>
                </div>
                <div class="mt-2 text-xs text-white text-right" v-if="a.pushButton && a.lastPushButtonTimestamp === a.lastMandownTimestamp">{{ $t("secur_track.man_down") }}</div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <AlarmSoundSettings />
    <AlarmGPSInfo :alarmInfo="infoSRT326" />
  </div>
</template>

<script>
import AlarmGPSInfo from "@/components/modal/alarm_gps_info";
import { gmapApi } from "vue2-google-maps";
import "@googlemaps/markerclustererplus";
import AlarmSoundSettings from "@/components/modal/alarm_sound_settings";
import AlarmSearch from "@/components/securetrack/alarm_search";
import AlarmSound from "@/components/securetrack/alarm_sound";
export default {
  name: "AlarmCenterTrack",
  title() {
    return `SecurTrack | SecurCloud`;
  },
  components: {
    AlarmGPSInfo,
    AlarmSoundSettings,
    AlarmSearch,
    AlarmSound,
  },
  props: {
    customerId: {
      type: String,
    },
  },
  data() {
    return {
      isMobile: false,
      mobileMenuActive: false,
      mapFullScreenControl: true,
      user: this.$store.state.user,
      alarm_center_id: this.$route.params.id,
      alarm_id: this.$route.params.alarm_id,
      firstLoad: true,
      alarm_center: null,
      customers: null,
      units: null,
      alarms: null,
      gps_alarms: null,
      gps_alarms_filtered: null,
      track_alarm: [],
      zoom: this.$store.state.mapZoom,
      center: this.$store.state.mapCenter,
      markers: [],
      markers_filtered: [],
      refreshIntervalRef: null,
      refreshInterval: 30000, // every 30s
      infoWindowPos: null,
      infoWinOpen: false,
      currentMidx: null,
      infoOptions: { content: "", pixelOffset: { width: 0, height: -35 } },
      alarmCircles: [],
      alarmCircles_filtered: [],
      push_button_paths: [],
      push_button_paths_filtered: [],
      track_alarm_paths: [],
      track_alarm_paths_filtered: [],
      search_text: "",
      search_active: false,
      infoSRT326: null,
      searchQuery: "",
    };
  },
  methods: {
    refreshGPSData() {
      this.refreshIntervalRef = setInterval(() => {
        if (this.gps_alarms.length > 0) this.getAlarmCenterGPSAlarms();
      }, this.refreshInterval);
    },

    getAlarmCenterGPSAlarms() {
      var vm = this;

      this.axios
        .get(`${process.env.VUE_APP_SERVER_URL}/alarm-center/${this.alarm_center_id}/gps/alarms`)
        .then((response) => {
          this.alarm_center = response.data.alarm_center;
          this.customers = response.data.customers;
          this.units = response.data.units;
          this.alarms = response.data.alarms;
          this.track_alarm = response.data.track_alarm;

          this.alarmCircles = [];
          this.push_button_paths = [];
          this.track_alarm_paths = [];
          this.markers = [];

          var larms = [];
          var push_btn_larms = [];

          for (let i = 0; i < response.data.gps.length; i++) {
            let el = response.data.gps[i];
            let a = _.find(this.alarms, { imei_number: el.imei_number });

            if (a) {
              el.alarm_id = a._id;
              el.tracking_active = false;
              el.tracking_id = null;
              el.show_test_alarm = false;
              // gps
              let lastSeenObj = this.getAlarmLastSeenTime(el.updatedAt);
              el.lastSeen = lastSeenObj.lastSeen;
              el.lastSeenTime = lastSeenObj.lastSeenTime;

              el.speed = el.alarm_speed;
              if (new RegExp(/^SRT/g).test(a.type)) el.speed = (el.alarm_speed * 1.852).toFixed(2);

              // Teltonika vehical speed
              if (new RegExp(/^TELTONIKA/g).test(el.type)) el.speed = el.teltonika_other_data.vehicle_speed || 0;

              // alarm
              el.alarm_name = a.unit_name;
              el.phone = a.phone;
              el.unit_id = a.parent_id;
              el.type = a.type;

              // battery
              el.batteryPercent = el.battery_percent;

              // Teltonika battery percantage
              if (new RegExp(/^TELTONIKA/g).test(el.type)) el.batteryPercent = Math.min(Math.floor(el.battery_voltage * 100 - 323), 100);

              let batteryBars = 0;
              if (el.batteryPercent > 80) batteryBars = 4;
              else if (el.batteryPercent > 50) batteryBars = 3;
              else if (el.batteryPercent > 25) batteryBars = 2;
              else if (el.batteryPercent > 10) batteryBars = 1;
              el.batteryBars = batteryBars;

              // gsm signal
              let signalPercent = 0;
              if (new RegExp(/^SRT/g).test(a.type)) {
                if (el.gsm_signal <= 31) signalPercent = Math.ceil(3.225 * el.gsm_signal);
                let signalBars = 0;
                if (signalPercent > 80) signalBars = 4;
                else if (signalPercent > 50) signalBars = 3;
                else if (signalPercent > 25) signalBars = 2;
                else if (signalPercent > 0) signalBars = 1;
                el.signalBars = signalBars;
              } else if (new RegExp(/^TELTONIKA/g).test(el.type)) {
                //gsm signal for Teltonika
                let signalBars = el.gsm_signal - 1 > 0 ? el.gsm_signal - 1 : 0;
                el.signalBars = signalBars;
              }

              // tag
              el.tagFound = false;
              if (el.tag_id && el.tag_timestamp && new Date().getTime() - new Date(el.tag_timestamp).getTime() < 300000) el.tagFound = true;

              //Teltonika Odometer in Km
              if (new RegExp(/^TELTONIKA/g).test(el.type)) el.totalOdometer = el.teltonika_other_data.obd_oem_total_mileage;

              //Teltonika Fuel Level in Ltr
              if (new RegExp(/^TELTONIKA/g).test(el.type)) {
                el.obdOemFuelLevel = el.teltonika_other_data.odb_oem_fuel_level * 0.1 || 0;
              }

              // accuracy radius
              el.accuracyRadius = 0;
              if (new RegExp(/^SRT/g).test(a.type)) {
                let userRangeError = 70;
                if (el.satellites >= 5) userRangeError = 30;

                if (el.hdop >= 0) el.accuracyRadius = Math.ceil(el.hdop * userRangeError);
              } else if (new RegExp(/^TWIG/g).test(a.type)) el.accuracyRadius = el.hdop;
              // twig devices
              else if (new RegExp(/^TELTONIKA/g).test(el.type)) {
                // TELTONIKA devices
                // satellites, hdop
                el.satellites = el.satellites || 0;
                el.hdop = el.hdop * 0.1 || 0;
                // accuracy radius
                let userRangeError = 70;
                if (el.satellites >= 5) userRangeError = 30;

                if (el.hdop >= 0) el.accuracyRadius = Math.ceil(el.hdop * userRangeError);
              }
              let unit = this.getUnit(a.parent_id);
              el.unit_name = unit ? unit.name : "";
              el.customer_id = unit ? unit.parent_id : "";
              el.customer_name = unit ? this.getCustomerName(unit.parent_id) : "";

              // Set icon depending on alarm model
              
              if (a.type != "SRT326" && el.gps_pos.lat != null) {

                let icon = require("@/assets/icon/new_small_map_marker.png");
                if (new RegExp(/^TELTONIKA/g).test(el.type)) icon = require("@/assets/icon/car.png");
                else if (a.type == "SRT430") icon = require("@/assets/icon/small_SRT430.png");
                else if (a.type == "SRT341") icon = require("@/assets/icon/srt341.png");
                else if (a.type == "SRT406") icon = require("@/assets/icon/small_SRT406.png");
                else if (a.type == "SRT406i") icon = require("@/assets/icon/small_SRT406.png");
                else if (/^TWIG/.test(a.type)) {
                  icon = require("@/assets/icon/twig_map_marker.png");

                  if (a.type == "TWIG One EX") icon = require("@/assets/icon/twig_one_ex.png");
                  else if (/^TWIG One/.test(a.type)) icon = require("@/assets/icon/twig_one.png");
                }
                else if (/^EMERIT/.test(a.type)) {
                  icon = require("@/assets/icon/emerit_watch.png");

                  if (a.type == "EMERIT e-B10") icon = require("@/assets/icon/smartphone.png");
                }

                this.markers.push({
                  icon: icon,
                  position: { lat: el.gps_pos.lat ? el.gps_pos.lat : 0, lng: el.gps_pos.lng ? el.gps_pos.lng : 0 },
                  infoText: `${this.$t("map_info_popup.alarm_name")}: <b>${el.alarm_name}</b><br>
                          ${this.$t("map_info_popup.gsm_signal_strength")}: <b>${this.getGSMSignalPercent(el.gsm_signal, el.type)}</b><br>
                          ${this.$t("map_info_popup.battery_percentage")}: <b>${el.batteryPercent} %</b><br>
                          ${this.$t("map_info_popup.speed")}: <b>${el.speed} km/hr</b><br>
                         ${this.$t("map_info_popup.lat")}: <b>${el.gps_pos.lat ? el.gps_pos.lat.toFixed(3) : "-"}</b>, Lng: <b>${el.gps_pos.lng ? el.gps_pos.lng.toFixed(3) : "-"}</b>
                          `,
                  alarmId: a._id,
                  imei_number: a.imei_number,
                });

                // push-button tracking 30 min.
                if (el.lastPushButtonTimestamp && new Date().getTime() - el.lastPushButtonTimestamp < 1800000) {
                  let push_button_path = _.find(this.track_alarm, { _id: el.lastPushButtonTrackID });
                  if (push_button_path) {
                    let path = _.map(push_button_path.gps_path, "position");
                    this.push_button_paths.push({
                      path: path,
                      alarmId: el.alarm_id,
                      imei_number: el.imei_number,
                      infoText: `<b>Push-Button spårning - ${el.alarm_name}</b>`,
                    });
                  }
                }

                // track user 30 min.
                if (el.alarm_track_user)
                  el.alarm_track_user.forEach((track_path) => {
                    if (new Date().getTime() - new Date(track_path.timestamp).getTime() < 1800000) {
                      let track_alarm = _.find(this.track_alarm, { _id: track_path.tracking_id, user_name: this.user.username });

                      if (track_alarm) {
                        let path = _.map(track_alarm.gps_path, "position");
                        this.track_alarm_paths.push({
                          path: path,
                          alarmId: el.alarm_id,
                          imei_number: el.imei_number,
                          infoText: `<b>Spårning - ${el.alarm_name}</b>`,
                        });

                        el.tracking_active = true;
                        el.tracking_id = track_path.tracking_id;
                      }
                    }
                  });
              }

              el.geofenceExit = false;
              if (el.lastGeofenceExit && this.moment().diff(this.moment(el.lastGeofenceExit), "minutes") <= 30) {
                el.geofenceExit = true;

                this.alarmCircles.push({
                  position: { lat: el.gps_pos.lat, lng: el.gps_pos.lng },
                  imei_number: el.imei_number,
                  alarmId: el.alarm_id,
                  options: {
                    fillColor: "orange",
                    fillOpacity: 0.5,
                    strokeColor: "orange",
                    strokeOpacity: 0.8,
                    strokeWeight: 2,
                    radius: 50,
                  },
                });
              }

              // push-button alert 5 min.
              el.pushButton = false;
              if (el.lastPushButtonTimestamp && new Date().getTime() - el.lastPushButtonTimestamp < 300000) {
                el.pushButton = true;
                push_btn_larms.push(el);

                if (a.type != "SRT326") {
                  this.alarmCircles.push({
                    position: { lat: el.gps_pos.lat, lng: el.gps_pos.lng },
                    imei_number: el.imei_number,
                    alarmId: el.alarm_id,
                    options: {
                      fillColor: "#FF0000",
                      fillOpacity: 0.5,
                      strokeColor: "#FF0000",
                      strokeOpacity: 0.8,
                      strokeWeight: 2,
                      radius: 50,
                    },
                  });
                }

                if (vm.$store.state.playingAlarmId !== a.imei_number) {
                  vm.$store.commit("SET_ALARM_ID", a.imei_number);
                  vm.$refs.audioControl.playAudio();
                }

                // test-alarm-btn
                // check if push button or mandown
                if (el.lastPushButtonTimestamp != el.lastMandownTimestamp) {
                  // todo: fix logic for showing btn if push btn is pressed repeat
                  if (Math.abs(new Date(a.last_test_alarm).getTime() - new Date(el.lastPushButtonTimestamp).getTime()) >= 300000) el.show_test_alarm = true;
                  else if (a.last_test_alarm == null) el.show_test_alarm = true;
                }
              } else larms.push(el);

              if (el.pushButton == false && el.accuracyRadius > 0 && a.type != "SRT326") {
                // accuracy circle
                this.alarmCircles.push({
                  position: { lat: el.gps_pos.lat, lng: el.gps_pos.lng },
                  imei_number: el.imei_number,
                  alarmId: el.alarm_id,
                  options: {
                    fillColor: "#ffce73",
                    fillOpacity: 0.2,
                    // strokeColor: '#ffc250',
                    strokeOpacity: 0,
                    strokeWeight: 0,
                    radius: el.accuracyRadius,
                  },
                });
              }
            }
          }

          push_btn_larms = push_btn_larms.sort(function(a, b) {
            return b.lastPushButtonTimestamp - a.lastPushButtonTimestamp;
          });
          this.gps_alarms = [...push_btn_larms, ...larms];

          if (this.search_active) this.searchAlarms();
          else {
            this.gps_alarms_filtered = this.gps_alarms;
            this.markers_filtered = this.markers;
            this.alarmCircles_filtered = this.alarmCircles;
            this.push_button_paths_filtered = this.push_button_paths;
            this.track_alarm_paths_filtered = this.track_alarm_paths;
          }

          if (this.firstLoad) {
            if (this.alarm_id) {
              this.$nextTick().then(() => {
                let a = _.find(this.gps_alarms, { _id: vm.alarm_id });
                if (a) vm.focusAlarm(a.imei_number);
              });
            } else {
              this.generateBounds();
            }
            this.firstLoad = false;
          }

          if (document.hasFocus()) this.handleSuccess("Plats uppdaterad", "top-right", 1000);
        })
        .catch((error) => {
          this.handleError(error, "Ett problem uppstod när plats uppdaterades");
        });
    },

    toggleInfoWindow(marker, idx) {
      this.infoWindowPos = marker.position;
      this.infoOptions.content = marker.infoText;

      //check if its the same marker that was selected if yes toggle
      if (this.currentMidx == idx) {
        this.infoWinOpen = !this.infoWinOpen;
        if (!this.infoWinOpen) this.alarm_id = "";
        else this.alarm_id = marker.alarmId;
      }
      //if different marker set infowindow to open and reset current marker index
      else {
        this.infoWinOpen = true;
        this.currentMidx = idx;
        this.alarm_id = marker.alarmId;
      }
    },

    showInfoWindow(event, infoText, show) {
      if (show) {
        this.infoWindowPos = event.latLng;
        this.infoOptions.content = infoText;
        this.infoWinOpen = show;
      } else this.infoWinOpen = show;
    },

    focusAlarm(imei) {
      var vm = this;

      let markerIndex = _.findIndex(this.markers, { imei_number: imei });

      if (markerIndex >= 0) {
        setTimeout(() => {
          vm.$refs.map.$mapObject.panTo(vm.markers[markerIndex].position);
          vm.$refs.map.$mapObject.setZoom(18);
        }, 300);

        this.$nextTick().then(() => {
          vm.toggleInfoWindow(this.markers[markerIndex], markerIndex);
        });
      } else {
        let alarm = _.find(this.gps_alarms, { imei_number: imei });
        this.showSRT326({
          alarm_name: alarm.unit_name,
          battery: alarm.battery_percent ? `${alarm.battery_percent} %` : "-",
          gsm_signal: alarm.gsm_signal ? this.getGSMSignalPercent(alarm.gsm_signal) : "-",
        });
      }

      if (this.isMobile) this.mobileMenuActive = false;
    },

    showSRT326(alarm) {
      this.infoSRT326 = alarm;
      this.$modal.show("modal-info-srt326");
    },

    getGSMSignalPercent(g, type) {
      if (new RegExp(/^TELTONIKA/g).test(type)) {
        return g * 20 + "%";
      }
      {
        // conversion as per SRT documentation
        if (g <= 31) return Math.ceil(3.225 * g) + " %";
        else return "N/A";
      }
    },

    getUnit(id) {
      let u = _.find(this.units, { unit_id: id });
      if (u) return u;
      else return null;
    },

    getCustomerName(id) {
      let c = _.find(this.customers, { _id: id });
      if (c) return c.name;
      else return "";
    },

    getAlarmLastSeenTime(updatedAt) {
      return {
        lastSeen: this.moment(updatedAt).fromNow(),
        lastSeenTime: this.moment().diff(this.moment(updatedAt)),
      };
    },

    async trackAlarm(gps, alarm_index) {
      try {
        if (!gps.tracking_active) {
          // start tracking
          let alarm = _.find(this.alarms, { imei_number: gps.imei_number });

          let speed = parseFloat(gps.alarm_speed);
          if (alarm && new RegExp(/^SRT/g).test(alarm.type)) speed = (parseFloat(gps.alarm_speed) * 1.852).toFixed(2);

          let data = {
            position: gps.gps_pos,
            speed: speed,
          };

          let response = await this.axios.post(`${process.env.VUE_APP_SERVER_URL}/gps/track-alarm/start/${gps.imei_number}`, data);
          let track_alarm = response.data.track_alarm;
          let path = _.map(track_alarm.gps_path, "position");

          this.track_alarm_paths.push({
            path: path,
            imei_number: gps.imei_number,
            alarmId: gps.alarm_id,
            infoText: `<b>Spårning - ${gps.alarm_name}</b>`,
          });

          this.gps_alarms_filtered[alarm_index].tracking_active = true;
          this.gps_alarms_filtered[alarm_index].tracking_id = track_alarm._id;

          this.focusAlarm(gps.imei_number);
          this.handleSuccess(`Spårning startad: ${gps.alarm_name}`);
        } else {
          // stop tracking
          let data = {
            tracking_id: gps.tracking_id,
          };

          let response = await this.axios.post(`${process.env.VUE_APP_SERVER_URL}/gps/track-alarm/stop/${gps.imei_number}`, data);
          if (response.data.success) {
            this.track_alarm_paths = _.remove(this.track_alarm_paths, { imei_number: gps.imei_number });
            this.gps_alarms_filtered[alarm_index].tracking_active = false;
            this.gps_alarms_filtered[alarm_index].tracking_id = null;
            this.handleSuccess(`Spårning avslutad: ${gps.alarm_name}`);
          }
        }
      } catch (error) {
        this.handleError(error);
      }
    },

    confirmTestAlarmPrompt(alarm, alarm_index) {
      this.$modal.show("dialog", {
        title: `Är du säker?`,
        text: `<p style="text-align: center;">Bekräfta testlarm för ${alarm.alarm_name}</p>`,
        buttons: [
          {
            title: '<div class="bg-accent-500 text-white text-sm font-sans py-2">NEJ</div>',
            handler: () => {
              this.$modal.hide("dialog");
            },
          },
          {
            title: '<div class="bg-red-500 text-white text-sm font-sans py-2">JA</div>',
            handler: () => {
              this.confirmTestAlarm(alarm, alarm_index);
            },
          },
        ],
      });
    },

    async confirmTestAlarm(alarm, alarm_index) {
      try {
        let response = await this.axios.get(`${process.env.VUE_APP_SERVER_URL}/alarm/confirm-test-alarm/${alarm.alarm_id}`);

        this.$modal.hide("dialog");

        if (response.data.success) {
          this.gps_alarms_filtered[alarm_index].show_test_alarm = false;
          this.handleSuccess(`Provlarm Bekräftat för ${alarm.unit_name}`);
        }
      } catch (error) {
        this.$modal.hide("dialog");
        this.handleError(error);
      }
    },

    generateBounds() {
      var vm = this;
      setTimeout(() => {
        vm.$refs.map.$mapPromise.then((map) => {
          const bounds = new vm.google.maps.LatLngBounds();
          for (let m of this.markers) {
            bounds.extend(m.position);
          }
          map.fitBounds(bounds);
        });
      }, 500);
    },

    checkMobile() {
      var vm = this;

      this.isMobile = window.innerWidth < 1024;

      if (this.isMobile) {
        vm.mobileMenuActive = false;
        vm.mapFullScreenControl = false;
      } else {
        this.mobileMenuActive = true;
        this.mapFullScreenControl = true;
      }
    },

    closeMenu() {
      this.mobileMenuActive = false;
    },

    generateMobileMenu() {
      var vm = this;

      let controlDiv = this.generateGoogleMapActionDiv();
      this.mobileMenuDiv = controlDiv;

      controlDiv.addEventListener("click", () => {
        vm.mobileMenuActive = true;
      });

      vm.$refs.map.$mapPromise.then((map) => {
        map.controls[vm.google.maps.ControlPosition.TOP_RIGHT].push(this.mobileMenuDiv);
      });
    },
    handleSearch(newQuery) {
      this.searchQuery = newQuery;
    },
    showSettingsModal() {
      this.$modal.show("alarm-sound-setting");
    },
  },

  computed: {
    google: gmapApi,
    filteredGPSAlarms() {
      if (!this.searchQuery) return this.gps_alarms; // If no search query, return all items
      const lowerCaseSearchQuery = this.searchQuery.toLowerCase();
      return this.gps_alarms.filter((item) => {
        return item.customer_name.toLowerCase().includes(lowerCaseSearchQuery) || item.unit_name.toLowerCase().includes(lowerCaseSearchQuery) || item.phone.includes(lowerCaseSearchQuery) || item.imei_number.includes(lowerCaseSearchQuery) || item.alarm_name.includes(lowerCaseSearchQuery);
      });
    },
  },

  created() {
    this.getAlarmCenterGPSAlarms();
    window.addEventListener("resize", this.checkMobile);
  },

  mounted() {
    this.refreshGPSData();
    this.generateMobileMenu();
    this.checkMobile();
  },

  beforeDestroy() {
    clearInterval(this.refreshIntervalRef);
    window.removeEventListener("resize", this.checkMobile);
  },
};
</script>
